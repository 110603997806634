<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 300px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
      @onBlur="onBlur"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { postdata } from "@/api";
// import { IEditorConfig } from "@wangeditor/editor";

export default Vue.extend({
  props: ["editorData"],
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容...", MENU_CONF: {} },
      mode: "default", // or 'simple'
    };
  },
  watch: {

    editorData(newVal) {
      if (newVal) {
        console.log("监听到prop[editorData]发生了变化", newVal);

        this.html = newVal;
      }
    },
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    onChange(editor) {
      //console.log("onChange", editor.children);
    },
    onBlur(editor) {
      this.$emit("sendEditor", this.html);
    },

    async dealdata(data) {
      return await postdata("/getjijiu", data).then((res) => {
        //console.log(res.data);
        return res;
      });
    },

    uploadfile() {
      let that = this;
      that.editorConfig.MENU_CONF["uploadImage"] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          console.log(file, insertFn);

          let formData = new FormData();
          formData.append("file", file);
          formData.append("flag", "file");
          formData.append("filepath", "jijiu20241014");

          let res = await that.dealdata(formData);

          console.log(res.data.data);

          const { name, url, code } = res.data.data[0];

          // <p><img src="15.jpg" alt="https://oss.laf.run/nnjacn-csco/jijiu20241014/15.jpg" data-href="success" style=""/>模拟 Ajax 异步设置内容 HTML</p>
          console.log(name, url, code);
          insertFn(url, code, name);
        },
      };
    },
  },

  created() {
    this.uploadfile();
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>模拟 Ajax 异步设置内容 HTML</p>";
    // }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
