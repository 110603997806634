<template>
  <div id="app">
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less">
html,
body{
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

h3{
  margin: 0;
  padding: 0;
}

</style>
