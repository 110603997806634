<template>
  <div>
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" @click="addnew">+新增数据</el-button>
        </el-form-item>
        <el-form-item label="急诊流程名">
          <el-input
            v-model="formInline.keyword"
            @input="searchinput"
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- <el-button type="primary" @click="addnew">+新增数据</el-button> -->

    <div style="margin-top: 8px">
      <el-table :data="userslist" style="width: 100%" border stripe>
        <el-table-column prop="name" label="急救流程名"></el-table-column>

        <!-- <el-table-column prop="img" label="急救流程图"> </el-table-column> -->
        <el-table-column prop="fileurl" label="附图">
          <template slot-scope="scope">
            <img
              :src="item.url"
              :min-width="70"
              :max-height="30"
              v-for="item in scope.row.uploadedFiles"
              :key="item.uid"
            />
          </template>
        </el-table-column>

        <el-table-column label="详情">
          <template #default="scope">
            <div>
              <quill-editor
                v-model="scope.row.desc"
                :options="editorOptions"
              ></quill-editor>
            </div>
          </template>
          <!-- <template #default="scope">
            <div v-html="scope.row.desc"></div>
            <mp-html :content="scope.row.desc" />
          </template> -->
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <span slot="title">
        <span class="header-main-title">{{
          isnew ? "新增数据" : "编辑数据"
        }}</span>
      </span>
      <el-form
        :inline="true"
        :rules="rules"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="急救流程名" prop="name">
          <el-input
            placeholder="请输入急救流程名"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件" prop="fileurl">
          <el-input placeholder="" v-model="form.fileurl"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { postdata } from "@/api";
import { mapMutations } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      userslist: [],
      isnew: true,
      dialogVisible: false,

      form: {
        name: "",
        img: "",
        fileurl: "",
      },

      formInline: {
        keyword: "",
      },

      html: "<div>Hello World!</div>",

      editorOptions: {
        placeholder: "在这里输入内容...",
        theme: "snow", // 主题样式，可以选择 'snow'、'bubble' 等,
        modules: {
          toolbar: false, // 禁用工具栏
        },
      },

      rules: {
        name: [{ required: true, message: "请输入流程名" }],
        img: [{ required: true, message: "请输入流程图" }],
      },
    };
  },

  mounted() {
    this.getdata();
  },

  methods: {
    //导入仓库
    ...mapMutations("jijiu", ["changeTitle", "changeList"]),
    handleClose() {
      console.log(this.$refs.form);
      this.dialogVisible = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        console.log(valid, "valid");
        if (valid) {
          if (this.isnew) {
            this.managedata({ ...this.form, flag: "new" });
            this.$message.error("添加成功");
          } else {
            this.managedata({ ...this.form, flag: "edit" });
          }
          //this.$refs.form.resetFields();
          this.dialogVisible = false;
        }
      });
    },
    cancel() {
      this.handleClose();
    },

    searchinput(e) {
      console.log(e);
      if (e) {
        this.onSearch();
      } else {
        this.getdata();
      }
    },

    async onSearch() {
      console.log("keyword---------------", this.formInline.keyword);
      await postdata("/getjijiu", {
        flag: "search",
        keyword: this.formInline.keyword,
      }).then((data) => {
        this.userslist = data.data;
        console.log("search数据", this.userslist);
      });
    },

    async managedata(data) {
      console.log("数据", data);
      await postdata("/getjijiu", data).then(async (res) => {
        console.log("getjijiu", res.data);
        await this.getdata(); // 调用获取用户信息的函数，更新列表
      });
    },

    async getdata() {
      await postdata("/getjijiu", { flag: "all" }).then((data) => {
        // 给每个对象添加一个新属性 'hobbies'，值为一个数组

        this.userslist = data.data;

        this.userslist.forEach((item) => {
          if (!item.hasOwnProperty("uploadedFiles")) {
            item.uploadedFiles = [];
          }
        });

        console.log("新数据", this.userslist);
      });
    },

    addnew() {
      //this.$refs.form.resetFields();
      this.isnew = true;
      this.form = {};
      //this.dialogVisible = true;
      this.$router.push("/jijiumanage");
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.isnew = false;
      //this.dialogVisible = true;
      this.form = row;

      this.changeTitle("编辑");
      this.changeList(row);

      console.log("编辑跳转前", row);

      this.$router.push("/jijiumanage");
    },
    handleDelete(index, row) {
      console.log("删除行的数据", { ...row, flag: "delete" });
      this.managedata({ ...row, flag: "delete" });
      //this.$message.error("删除成功");
    },
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.searchbox {
  background-color: white;
  height: 66px;
}

.demo-form-inline {
  padding: 10px;
  display: flex;
  align-items: center !important ;
}
</style>
