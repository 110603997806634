<template>
  <div class="formbox">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-form-item label="疾病名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>

      <el-form-item label="附加文件" required>
        <el-upload
          v-model="ruleForm.img"
          ref="upload"
          action=""
          :show-file-list="true"
          :auto-upload="true"
          :file-list="drawerFileList"
          multiple
          :limit="3"
          :on-exceed="exceedFile"
          :headers="uploadHeaders"
          :http-request="uploadToLaf"
          :on-remove="delete_drawerFileList"
          list-type="picture-card"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <!-- <div class="file-list">
          <span v-for="(item, index) in drawerFileList" :key="item.url">
            <el-link
              class="file-link"
              :underline="false"
              icon="el-icon-link"
              type="primary"
              :href="item.url"
              target="_blank"
              >{{ item.name }}</el-link>
            <i @click="delete_drawerFileList(index)" class="el-icon-delete el-icon--right"></i>
          </span>
        </div> -->
      </el-form-item>
      <el-form-item label="详情" prop="desc"
        ><wang-editor @sendEditor="sendEditor" :editorData="ruleForm.desc"></wang-editor>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >立即创建</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { postdata } from "@/api";
import WangEditor from "@/components/WangEditor/WangEditor.vue";
import { mapState } from "vuex";
export default {
  //props: ["nodeData", "formData"],

  props: {
    formData: {
      type: Object,
      required: true,
    },
    nodeData: {
      type: Object,
    },
  },

  components: { WangEditor },
  data() {
    return {
      ruleForm: {
        _id: "",
        name: "",
        flag: "data",
        uploadedFiles: [],
        desc:""
      },
      drawerFileList: [],
      uploadHeaders: {
        // "Authorization":
        //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEyMywiZXhwIjoxNzU5NTUyNDIyLCJpYXQiOjE3MjgwMTY0MjJ9.KtWEb5Pgi7bwEbh0spD_UG7SuCctg05C3CYUf-FVgUs",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 65,
            message: "长度在 2 到 65 个字符",
            trigger: "blur",
          },
        ],
        // classname: [
        //   { required: true, message: "请选择活动区域", trigger: "change" },
        // ],
        //picture: [{ required: true, message: "请选择图片", trigger: "change" }],
        //desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
    };
  },

  watch: {
    nodeData(newVal) {
      if (newVal) {
        console.log("监听到prop[nodeData]发生了变化", newVal);

        this.ruleForm.name = newVal.label;
      }
    },

    // formData(newVal) {
    //   if (newVal) {
    //     console.log("监听到prop[formData]发生了变化", newVal);
    //     this.ruleForm.name = newVal.name;
    //     this.drawerFileList = newVal.uploadedFiles;
    //   }
    // },
  },

  computed: {
    ...mapState("jijiu", ["title", "list"]),
  },

  mounted() {
    this.getdata();

    if (this.title == "编辑") {
      this.$nextTick(() => {
        this.ruleForm = { ...this.formData, flag: "data" };
        this.drawerFileList = this.ruleForm.uploadedFiles;
        console.log("######## Form ###########", this.ruleForm);
      });
    }
  },

  methods: {
    sendEditor(val) {
      console.log('从WangEditor传来的数据',val);

      this.ruleForm.desc = val
    },
    updateForm(field, value) {
      this.$store.dispatch("setFormData", { [field]: value });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.error("只能选择1个文件", files, fileList);
    },
    // 自定义上传到 LAF 的请求
    async uploadToLaf({ file, onSuccess, onError }) {
      try {
        console.log("file列表", file);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("flag", "file");
        formData.append("filepath", "jijiu20241013");

        onSuccess();

        let fileurl = this.dealdata(formData);
        fileurl.then((r) => {
          let res = r.data.data;
          console.log("################", res);
          let obj = { name: res[0].name, url: res[0].url };

          let drawerFileListArray = this.drawerFileList || [];
          if (!drawerFileListArray.find((file) => file.name === obj.name)) {
            this.drawerFileList = drawerFileListArray.concat(obj);
          } else {
            console.log(
              "文件重复",
              !drawerFileListArray.find((file) => file.name === obj.name)
            );
            this.drawerFileList = drawerFileListArray;
          }

          let uploadedFilesArray = this.ruleForm.uploadedFiles || [];
          if (!uploadedFilesArray.find((file) => file.name === obj.name)) {
            this.ruleForm.uploadedFiles = uploadedFilesArray.concat(obj);
          } else {
            console.log("文件重复");
          }
        });
      } catch (error) {
        onError(error);
      }
    },

    async delete_drawerFileList(i) {
      // console.log(
      //   "删除前文件",
      //   this.drawerFileList,
      //   this.ruleForm.uploadedFiles
      // );

      this.deletefile = this.drawerFileList.filter(
        (item) => item.name === i.name
      );

      if (this.deletefile.length === 0) {
        console.log("未找到需删除的文件");
        return; // 如果未找到匹配文件，提前退出
      }

      console.log("需删除文件", this.deletefile);

      const [delurl] = this.deletefile[0].url.split("?"); // 获取 URL 的第一部分
      console.log("删除的文件 URL", delurl);

      const res2 = delurl.split("https://oss.laf.run/nnjacn-csco/");
      if (res2.length > 1) {
        const filePath = res2[1]; // 获取文件路径部分
        console.log("待删除的文件路径:", filePath);

        // 进一步操作：比如调用 API 删除该文件
      } else {
        console.error("URL 格式不正确，无法拆分文件路径");
      }

      await postdata("/getjijiu", {
        flag: "deletefile",
        fileurl: res2[1],
        _id: this.ruleForm._id,
        uid: this.deletefile[0].uid,
      }).then((res) => {
        let result = res.data;
        console.log(result);
        return result;
      });

      // 在前端从 fileList 中移除文件
      this.drawerFileList = this.drawerFileList.filter(
        (item) => item.name !== i.name
      );

      // 可以根据 file.url 或其他标识来确定删除的文件
      this.ruleForm.uploadedFiles = this.ruleForm.uploadedFiles.filter(
        (item) => item.name !== i.name
      );
      // console.log(
      //   "删除后文件",
      //   this.drawerFileList,
      //   this.ruleForm.uploadedFiles
      // );

      //this.drawerFileList.splice(i, 1);
      //this.ruleForm.uploadedFiles = this.ruleForm.uploadedFiles.splice(i, 1);
    },
    uploadFileDrawer(params) {
      console.log(params, "ggggggggg");
      this.ruleForm.append("file", params.file);
    },

    async dealdata(data) {
      return await postdata("/getjijiu", data).then((res) => {
        //console.log(res.data);
        return res;
      });
    },

    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // if (this.drawerFileList.length == 0) {
          //   this.$message.error("请上传附件");
          //   return;
          // }
          that.dealdata(this.ruleForm);
          that.$router.push("/jijiu");
        } else {
          console.log("必填项未填完");
          return false;
        }
      });
    },

    async getdata() {
      const res = await postdata("/getjijiu", { flag: "fl" }).then((data) => {
        let result = data.data;
        console.log("取回急救数据", result);
        return result;
      });
      console.log("getjijiu", res);
      //this.options = res.titlearr;
    },
  },
};
</script>

<style lang="less" scoped>
.formbox {
  background-color: white;
  padding-top: 6px;

  height: 100%;
}
</style>

<!-- // 上传成功后处理每个文件的响应 //handleSuccess(response, file, fileList) { //
假设服务器返回的响应包含文件 URL //console.log("服务器返回的响应包含文件 URL",
response, file, fileList); // if (response.code === "success") { //
this.drawerFileList.push({ // name: file.name, // url: response.url, // 将文件
URL 存储 // }); // this.successCount++; // // 检查是否所有文件上传成功 // if
(this.successCount === fileList.length) { //
this.$message.success("所有文件上传成功！"); // } // } else { //
this.$message.error(`文件 ${file.name} 上传失败`); // } //}, -->
