import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import jijiu from './jijiu'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tab,
        jijiu
    },
    state: {
        formData: {
            name: ''
        }
    },
    mutations: {
        updateFormData(state, payload) {
            state.formData = {
                ...state.formData,
                ...payload
            };
        }
    },
    actions: {
        setFormData({
            commit
        }, payload) {

            console.log('payload',
                payload);
            
            commit('updateFormData', payload);
        }
    }
})