import { render, staticRenderFns } from "./WangEditor.vue?vue&type=template&id=1ce60650"
import script from "./WangEditor.vue?vue&type=script&lang=js"
export * from "./WangEditor.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports